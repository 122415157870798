<template>
  <div>
    <el-dialog title="顾问机会添加" v-if="isShowAddOppor.isShow" :visible.sync="isShowAddOppor.isShow" width="60%">
      <el-form :model="addOpporForm" ref="addOpporForm" :inline="true" :rules="addOpporRules"
               label-position="right" label-width="80px">
        <el-form-item label="资源类别" prop="type" size="mini">
          <el-select v-model="addOpporForm.type" class="condition-select" placeholder="请选择">
            <el-option label="新名片首咨" value="1"></el-option>
            <!--                        <el-option label="个人创建" value="2"></el-option>-->
            <el-option label="名片公海" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="telephone" size="mini">
          <el-input v-model="addOpporForm.telephone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="唯一值" size="mini" prop="possessId">
          <el-select v-model="addOpporForm.possessId" class="condition-select" placeholder="请选择唯一值">
            <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="咨询科目" prop="subjectIds" size="mini">
              <el-select v-model="addOpporForm.subjectIds" class="condition-select" placeholder="请选择咨询科目">
                  <el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName"
                             :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="渠道" prop="mediatorId" size="mini">
              <el-select v-model="addOpporForm.mediatorId" class="condition-select" placeholder="请选择渠道">
                  <el-option v-for="item in mediatorList" :key="item.id" :label="item.mediatorName"
                             :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
        <!--  开始额外参数功能  -->
        <el-form-item v-for="info in columns" size="mini"
                      v-if="info.status && info.detail"
                      :label="info.label"
                      :required="info.required">
          <el-col v-if="info.type === 'input'">
            <el-input v-model="information[info.name]"></el-input>
          </el-col>
          <el-col v-if="info.type === 'radio'">
            <el-select v-model="information[info.name]" placeholder="请选择">
              <el-option v-for="(item,index) in info.tson.dictList" :key="index"
                         :label="item.value"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="info.type === 'checkbox'">
            <el-select v-model="information[info.name]" multiple placeholder="请选择">
              <el-option v-for="(item,index) in info.tson.dictList" :key="index"
                         :label="item.value"
                         :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="info.type === 'date'">
            <el-date-picker
                v-model="information[info.name]"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-col>

          <el-col v-if="info.type === 'time'">
            <el-date-picker
                v-model="information[info.name]"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-col>

          <el-col v-if="info.type === 'number'">
              <el-input v-model="information[info.name]" type="number"></el-input>
          </el-col>

          <el-col v-if="info.type === 'money'">
              <el-input v-model="information[info.name]" oninput="value=value.replace(/[^0-9.]/g,'')">
                  <span slot="suffix">元</span>
              </el-input>
          </el-col>
          <el-col v-if="info.type === 'DICT'">
              <el-select v-model="information[info.name]"
                         class="condition-select" clearable
                         placeholder="请选择">
                  <el-option label="未指定" :value="undefined"></el-option>
                  <el-option v-for="item in extraMap[info.name]" :key="item.id"
                             :label="item.dictLabel" :value="item.id">
                  </el-option>
              </el-select>
          </el-col>
        </el-form-item>
        <!--     结束额外参数         -->
        <el-form-item label="姓名" prop="studentName" size="mini">
          <el-input v-model="addOpporForm.studentName" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item label="性别" size="mini">
          <el-select v-model="addOpporForm.sex" class="condition-select" placeholder="请选择性别">
            <el-option label="男" value="0"></el-option>
            <el-option label="女" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期" size="mini">
          <el-date-picker
              v-model="addOpporForm.birthday" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="电子邮箱" size="mini">
          <el-input v-model="addOpporForm.email" placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item label="QQ号码" size="mini">
          <el-input v-model="addOpporForm.qq" placeholder="请输入QQ号码"></el-input>
        </el-form-item>
        <el-form-item label="微信" size="mini">
          <el-input v-model="addOpporForm.weiXin" placeholder="请输入微信"></el-input>
        </el-form-item>
        <el-form-item label="所属地区" size="mini">
          <el-input v-model="addOpporForm.addresss" placeholder="所属地区"></el-input>
        </el-form-item>
        <el-form-item label="年龄" size="mini">
          <el-input v-model="addOpporForm.verifyAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="学历" size="mini">
          <el-input v-model="addOpporForm.verifyEducation" placeholder="请输入学历"></el-input>
        </el-form-item>
        <el-form-item label="学员条件" size="mini">
          <el-select v-model="addOpporForm.cadetConditions" class="condition-select">
            <el-option label="未报考符合条件" value="1"></el-option>
            <el-option label="未报考不符合条件" value="2"></el-option>
            <el-option label="已报考符合条件" value="3"></el-option>
            <el-option label="已报考不符合条件" value="4"></el-option>
            <el-option label="专科成教" value="5"></el-option>
            <el-option label="专科自考" value="6"></el-option>
            <el-option label="本科成教" value="7"></el-option>
            <el-option label="本科自考" value="8"></el-option>
            <el-option label="未接通" value="9"></el-option>
            <el-option label="接通未知" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向级别" size="mini">
          <el-select v-model="addOpporForm.intention" class="condition-select" placeholder="请选择意向级别">
            <el-option label="请选择意向级别" value></el-option>
            <el-option label="1" value="高意向"></el-option>
            <el-option label="2" value="意向"></el-option>
            <el-option label="3" value="跟踪"></el-option>
            <el-option label="4" value="无意向"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowAddOppor.isShow= false" size="mini">取 消</el-button>
        <el-button type="primary" @click="doSubmit" size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {saveOpportunity} from "@/utils/api";
import * as searchApi from "@/api/system/searchDeploy";
import * as mediatorApi from "@/api/crm/mediatorCut";
import * as dictApi from "@/api/system/dictionary";

export default {
  name: "TheAddClue",
  props: {
    isShowAddOppor: {
      type: Object,
      default: null
    },
    subjectList: {
      type: Array,
      require: false,
      default: () => {
        return []
      }
    },
    possessList: {
      type: Array,
      require: false,
      default: () => {
        return []
      }
    },
    adviser: {
      type: Number,
      require: false,
      default: () => {
        return 1
      }
    },
    pondId: {
        type: Number,
        require: false,
        default: () => {
            return 1
        }
    }
  },
  data() {
    return {
      extraMap:{
          "BHBIIGGHFE":[],
          "BHBIGCFBGB":[],
          "BHBIGCFCIH":[],
      },
      addOpporForm: {
          type:"1",
      },//添加机会对象
      addOpporRules: {
        subjectIds: [{required: true, message: '咨询科目不可为空', trigger: 'blur'}],
        type: [{required: true, message: '资源类别不可为空', trigger: 'blur'}],
        telephone: [{required: true, message: '手机号码不可为空', trigger: 'blur'}, {
          min: 11,
          max: 11,
          message: "手机号长度只可以为11",
          trigger: 'blur'
        }],
        possessId: [
          {required: true, message: '唯一配置不可为空', trigger: 'change'}
        ]
      },
      subjectData: [],
      possessData: [],
      columns: [],
      information: {},
      tson: {},
        mediatorList: [],
    }
  },
  created() {
    this.getColumn();
    this.getMediatorList();
    this.findExtraMap()
  },
  methods: {
      /**
       * 组装额外参数字典
       */
      findExtraMap(){
          dictApi.findExtraDictMap().then(res=>{
              if(res.success){
                  this.extraMap = res.data
              }
          })
      },
      getMediatorList() {
          mediatorApi.listMediator({page: 1, limit: 99999}).then(res => {
              if (res.success) {
                  this.mediatorList = res.data.data
              }
          })
      },
    getColumn: function () {
      searchApi.findByClassify("NEW_INFORMATION").then(res => {
        if (res.data) {
          let entity = res.data;
          for (let e of entity) {
            let tson = JSON.parse(e.data)
            e["tson"] = tson
          }
          this.columns = entity;
        }

      });
    },
    doSubmit() {

      // console.log(this.tson)
      if (Object.keys(this.tson).length > 0) {
        let extra = JSON.stringify(this.tson)
        this.addOpporForm['extra'] = extra
      }

      // console.log(this.addOpporForm)


      this.$refs['addOpporForm'].validate((valid) => {
        if (valid) {
          this.addOpporForm['inputAccountId'] = this.adviser
          this.addOpporForm['pondId'] = this.pondId
            // console.log(this.addOpporForm);
          // return ;
          saveOpportunity(this.addOpporForm).then(res => {
            if (res.success) {
              this.$message.success(res.msg)
              this.isShowAddOppor.isShow = false
              this.$emit("reload")
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  watch: {
    information: function (val, old) {
      this.tson = val
    },
    "isShowAddOppor.isShow": function (newValue, oldValue) {
      if (newValue) {
        this.addOpporForm = {}
      }
    }
  }

}
</script>

<style scoped>

</style>
